export const TitleWeb = "MC Control";
export const AuthProvider = "mccontrol";
export const DefaultSponsor = "mccontrol";
export const DomainName = "https://mccontrol.ltd/";
export const DomainRoot = "https://mccontrol.ltd/";
export const NameInc = "MC Control Inc.";
export const EmailApp = "admin@mccontrol.ltd";
export const ContactAdmin = "+60 8-8787-5353";

// COLOR PALETTE
export const PrimaryColor = "#2962ff";
export const PrimaryDark = "#2E0101";
export const SecondaryColor = "#FFC300";
export const SecondaryDark = "#3D2F01";
export const TextColor = "#535353";
